import connector, { Connector } from 'Webapp/utils/connector';

import { isSectionSlugRoute } from 'Webapp/shared/app/redux/selectors/routing';
import {
  getCurrentSection,
  getPrimarySectionItems,
  getSectionTopicDescriptions,
} from 'Webapp/shared/app/redux/selectors/section';
import { currentUserUid } from 'Webapp/shared/app/redux/selectors/auth';
import {
  usageTrackSectionEnter,
  usageTrackSectionExit,
  usageSetMobileGateSection,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import {
  getSection,
  setSectionAccessed,
  setSectionEphemeral,
} from '../redux/actions/section-actions';
import { queueLoadMissingSocialActivity } from 'Webapp/shared/concepts/social-activity';
import {
  setAdjustNavFrom,
  setNavAppStoreUrl,
  setNavFromOverride,
  setTitle,
  setAppTheme,
  setAppUrl,
} from '../redux/actions/app';
import { toastShowErrorAction } from '../redux/actions/toast-actions';
import Section from '../views/section';

// Utils
import SectionUtil from 'Utils/content/section-util';

const selectors = {
  section: getCurrentSection,
  isLoading: (state: Flipboard.State) => {
    const currentSection = getCurrentSection(state);
    return (
      currentSection &&
      SectionUtil.isSectionLoading(
        currentSection.requestedRemoteId,
        state.sections.loading,
      )
    );
  },
  userid: currentUserUid,
  items: getPrimarySectionItems,
  topicDescriptions: getSectionTopicDescriptions,
  nextPageKey: (state: Flipboard.State) => getCurrentSection(state).nextPageKey,
  previousRawItems: (state: Flipboard.State) => {
    const currentSection = getCurrentSection(state);
    return currentSection.rawItems;
  },
  nglFeedConfigs: ({ app }: Flipboard.State) => app.nglFeedConfigs,
  navFromOverride: ({ app }: Flipboard.State) => app.navFromOverride,
  isSectionSlugRoute,
  smartMagazines: ({ profile: { smartMagazines } }: Flipboard.State) =>
    smartMagazines,
};

const actions = {
  getSection,
  setSectionAccessed,
  setAdjustNavFrom,
  setNavAppStoreUrl,
  setNavFromOverride,
  setTitle,
  setAppTheme,
  setAppUrl,
  toastShowErrorAction,
  usageTrackSectionEnter,
  usageTrackSectionExit,
  usageSetMobileGateSection,
  setSectionEphemeral,
  queueLoadMissingSocialActivity,
};

export type SectionContainerProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const sectionConnector: Connector = {
  selectors,
  actions,
};

const SectionContainer = connector(sectionConnector)(Section);

export default SectionContainer;
