import { Connector } from 'Utils/connector';

import { setAdjustNavFrom, setAdjustSectionId } from '../redux/actions/app';

const selectors = {
  adjustNavFrom: ({ app: { adjustNavFrom } }: Flipboard.State) => adjustNavFrom,
  adjustSectionId: ({ app: { adjustSectionId } }: Flipboard.State) =>
    adjustSectionId,
  utmParams: ({ app: { utmParams } }: Flipboard.State) => utmParams,
};

const actions = {
  setAdjustNavFrom,
  setAdjustSectionId,
};

export type ConnectAdjustParamsProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectAdjustParams: Connector = {
  selectors,
  actions,
};

export default connectAdjustParams;
