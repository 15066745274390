import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

// Utils
import { getProfilePath, getSectionId } from 'Utils/content/flipboard-urls';
import { GA } from 'Utils/analytics';
import { USAGE_NAV_FROMS } from 'Utils/analytics/usage';
import { AppTheme } from 'Webapp/enums';
import scrollTo from 'Utils/scroll-to';
import FlapUtil from 'Utils/content/flap-util';
import { euc, duc } from 'Utils/url';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';
import { getSmartMagazineSectionById } from 'Utils/content/profile-util';

// Components
import Loading from '../components/loading';
import { NavFromContext } from 'ComponentLibrary/context';

// HOCs
import withT from 'ComponentLibrary/hocs/withT';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

import connector from 'Utils/connector';
import connectAppUrl, {
  ConnectAppUrlProps,
} from 'Webapp/shared/app/connectors/connectAppUrl';
import connectAuthentication, {
  ConnectAuthenticationProps,
} from 'Webapp/shared/app/connectors/connectAuthentication';
import connectAdjustParams, {
  ConnectAdjustParamsProps,
} from 'Webapp/shared/app/connectors/connectAdjustParams';
import connectRouting, {
  ConnectRoutingProps,
} from 'Webapp/shared/app/connectors/connectRouting';
import connectContextualOnboarding, {
  ConnectContextualOnboardingProps,
} from 'Webapp/shared/app/connectors/connectContextualOnboarding';
import connectAmp, {
  ConnectAmpProps,
} from 'Webapp/shared/app/connectors/connectAmp';
import connectSocialActivity, {
  ConnectSocialActivityProps,
} from 'Webapp/shared/app/connectors/connectSocialActivity';
import connectComments, {
  ConnectCommentsProps,
} from 'Webapp/shared/app/connectors/connectComments';
import { SectionContainerProps } from 'Webapp/shared/app/route-containers/section';

const SectionComponentDefault = codeSplitLoad('SectionComponentDefault');
const SectionComponentMagazine = codeSplitLoad('SectionComponentMagazine');
const SectionComponentProfile = codeSplitLoad('SectionComponentProfile');
const SectionComponentTopic = codeSplitLoad('SectionComponentTopic');
const SectionComponentStoryboard = codeSplitLoad('SectionComponentStoryboard');

type SectionProps = ConnectAppUrlProps &
  ConnectAuthenticationProps &
  ConnectAdjustParamsProps &
  ConnectRoutingProps &
  ConnectContextualOnboardingProps &
  ConnectAmpProps &
  ConnectSocialActivityProps &
  ConnectCommentsProps &
  SectionContainerProps & {
    t: Flipboard.TFunction;
    history: RouteComponentProps['history'];
  };

class Section extends Component<SectionProps> {
  componentDidMount() {
    const { section, routing } = this.props;
    // Do not fetch on initial load, if the view was generated on the server
    if (this.isInvalidStoryboard()) {
      this.handleInvalidStoryboard();
      return;
    }
    if (section && section.remoteid) {
      this.handleSectionChanged();
    }
    this.fetchSection();
    this.setAppTheme();

    this.props.setAdjustNavFrom(USAGE_NAV_FROMS.FEED);

    if (!this.shouldRewriteProfilePath()) {
      this.trackPageview(section, routing.pathname);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      section,
      routing,
      navFromOverride,
      setNavFromOverride,
      history,
      setSectionEphemeral,
      isLoading,
    } = this.props;

    if (
      !isLoading &&
      (getSectionId(routing.params) !==
        getSectionId(prevProps.routing.params) ||
        (prevProps.section.stale !== section.stale && section.stale))
    ) {
      this.fetchSection();
    }
    if (!section) {
      return;
    }
    if (this.isInvalidStoryboard()) {
      this.handleInvalidStoryboard();
      return;
    }

    this.setAppTheme();

    const { remoteid } = section;
    const previousRemoteid = prevProps.section && prevProps.section.remoteid;
    const previousSectionRemoved = remoteid !== previousRemoteid;
    if (previousSectionRemoved && prevProps.section.isTopic) {
      // set previous as ephemeral, as soon as it is no longer the current section.
      setSectionEphemeral(previousRemoteid, true);
    }

    const sectionChanged = remoteid && previousSectionRemoved;

    if (previousRemoteid && sectionChanged && navFromOverride) {
      setNavFromOverride(null);
    }
    if (sectionChanged) {
      this.handleSectionChanged();

      if (this.shouldRewriteProfilePath()) {
        history.replace(getProfilePath(section, false));
      } else {
        this.trackPageview(section, routing.pathname);
      }
    }
  }

  componentWillUnmount() {
    this.props.setNavFromOverride(null);
    this.props.setAppUrl(null);
    this.props.setAdjustNavFrom(undefined);

    const { section } = this.props;
    if (section.isTopic) {
      this.props.setSectionEphemeral(section.remoteid, true);
    }
  }

  setAppTheme = () => {
    const { section, setAppTheme, isAmp } = this.props;
    if (section.placeholder) {
      return;
    }
    setAppTheme(
      section.isStoryboard && isAmp ? AppTheme.DARK : AppTheme.DEFAULT,
    );
  };

  trackPageview = (section: Flipboard.Section, pathname: string) => {
    // Don't track when the section is just a placeholder
    if (section.placeholder) {
      return null;
    }

    const {
      usageTrackSectionEnter,
      usageTrackSectionExit,
      setTitle,
      history,
      usageSetMobileGateSection,
    } = this.props;
    usageTrackSectionExit();
    usageTrackSectionEnter(section, history);
    usageSetMobileGateSection(section);
    GA.trackPageview(setTitle({ section }), pathname, section);
  };

  isInvalidStoryboard = () => {
    const { section, routing } = this.props;

    return (
      routing.path === '/@:username/:slug' &&
      section &&
      !section.placeholder &&
      !section.isValid
    );
  };

  handleInvalidStoryboard = () => {
    const { routing, toastShowErrorAction, t } = this.props;

    toastShowErrorAction(t('invalid_storyboard_error'));

    const username = routing && routing.params && routing.params.username;
    if (username) {
      this.props.history.replace(`/@${username}`);
    }
  };

  handleSectionChanged = () => {
    const {
      section,
      setSectionAccessed,
      setNavAppStoreUrl,
      setAppUrl,
      loadPreviousAndSaveCurrentVisitedItems,
      setTitle,
      queueLoadMissingSocialActivity,
    } = this.props;
    // Update the nav bar Adjust link if section is a storyboard (NGL)
    setAppUrl(section.appUrl);
    setNavAppStoreUrl(section);
    setSectionAccessed(section);
    setTitle({ section });
    loadPreviousAndSaveCurrentVisitedItems(section);

    queueLoadMissingSocialActivity();

    scrollTo();
  };

  // If we find that we are rendering a profile with a /section/slug style path,
  // replace the history with a proper profile path.  Happens when navigating to
  // profiles from the favorites menu.
  shouldRewriteProfilePath = () => {
    const { section, isSectionSlugRoute } = this.props;
    return section && section.isProfile && isSectionSlugRoute;
  };
  extractSectionId = () => {
    const {
      routing: { params },
      smartMagazines,
    } = this.props;
    const sectionId = getSectionId(params);
    const foundSmartMag = getSmartMagazineSectionById(
      sectionId,
      smartMagazines,
    );
    return foundSmartMag?.remoteid || duc(sectionId);
  };

  fetchSection = () => {
    // Use route params
    const {
      section,
      topicDescriptions,
      routing: { url },
      nglFeedConfigs,
      getSection,
      purgeComments,
      purgeSocialActivity,
    } = this.props;
    if (
      section &&
      !section.stale &&
      !section.placeholder &&
      section.primarySectionForRoute
    ) {
      return;
    }
    purgeComments();
    purgeSocialActivity();
    const sectionId = this.extractSectionId();
    if (sectionId) {
      getSection(sectionId, {
        path: url,
        forNavigation: true,
        nglFeedConfigs,
        topicDescriptions,
        primarySectionForRoute: true,
        ephemeral: !FlapUtil.isTopicSectionId(sectionId),
      });
    }
  };

  loadNextPage = () => {
    const {
      routing: { url },
      section,
      topicDescriptions,
      nextPageKey,
      previousRawItems,
      nglFeedConfigs,
      getSection,
    } = this.props;

    if (!section || section.neverLoadMore) {
      return;
    }

    let sectionId = this.extractSectionId();

    if (section.invite) {
      sectionId = `${sectionId}?inviteToken=${euc(section.invite.inviteToken)}`;
    }

    return getSection(sectionId, {
      path: url,
      forNavigation: true,
      pageKey: nextPageKey,
      nglFeedConfigs,
      topicDescriptions,
      previousRawItems,
      primarySectionForRoute: section.primarySectionForRoute,
      loadCommentary: true,
    });
  };

  render() {
    const { section, userid, topicDescriptions, isAmp } = this.props;
    if (!section || section.placeholder || section.stale) {
      return <Loading isView />;
    }

    let SectionComponent;

    if (section.isStoryboard) {
      // Render storyboard layout if section is a storyboard
      SectionComponent = SectionComponentStoryboard;
    } else if (section.isMagazine) {
      SectionComponent = SectionComponentMagazine;
    } else if (section.isProfile) {
      SectionComponent = SectionComponentProfile;
    } else if (!isAmp && (section.isTopic || section.isSmartMagazine)) {
      /* Currently the new topic layout is only for logged out users. */
      SectionComponent = SectionComponentTopic;
    } else {
      SectionComponent = SectionComponentDefault;
    }

    return (
      <NavFromContext.Provider value={USAGE_NAV_FROMS.FEED}>
        <SectionComponent
          section={section}
          userid={userid}
          loadNextPage={this.loadNextPage}
          topicDescriptions={topicDescriptions}
        />
      </NavFromContext.Provider>
    );
  }
}

export default connector(
  connectAmp,
  connectContextualOnboarding,
  connectRouting,
  connectAppUrl,
  connectAuthentication,
  connectAdjustParams,
  connectComments,
  connectSocialActivity,
)(withT(withHistory(Section)));
